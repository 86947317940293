import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";
import headerBackground from "../images/head.png";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerBackground})`,
    minHeight: "194px",
  },
}));
export const StyledKeyVisual = styled.img`
  width: 100%;
  height: 100%;
  min-height: 194px;
  position: absolute;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledButton = styled.button`
  padding: 0;
  margin: 20px 0 0 0;
  border-radius: 100%;
  border: solid 2.5px #fff;
  background-color: rgba(0, 0, 0, 0);
  font-weight: bold;
  color: var(--secondary-text);
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

function Header() {
  const classes = useStyles();
  return (
    <AppBar position="relative" className={classes.header}>
      <Toolbar>
        <Grid container direction="column">
          <Grid item container>
            <Grid xs={6} sm={9}></Grid>
            <Grid xs={1} sm={1}>
              <AnchorLink href="#？？">
                <StyledButton>？？</StyledButton>
              </AnchorLink>
            </Grid>
            <Grid xs={1} sm={1}>
              <AnchorLink href="#？？">
                <StyledButton>？？</StyledButton>
              </AnchorLink>
            </Grid>
            <Grid xs={1} sm={1}>
              <AnchorLink href="#？？">
                <StyledButton>？？</StyledButton>
              </AnchorLink>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
