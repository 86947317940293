// log
import store from "../store";

const fetchDataSubRequest = () => {
  return {
    type: "CHECK_DATASUB_REQUEST",
  };
};

const fetchDataSubSuccess = (payload) => {
  return {
    type: "CHECK_DATASUB_SUCCESS",
    payload: payload,
  };
};

const fetchDataSubFailed = (payload) => {
  return {
    type: "CHECK_DATASUB_FAILED",
    payload: payload,
  };
};

export const fetchDataSub = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataSubRequest());
    try {
      // 保有数取得
      let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account)
        .call();

      let tokenURI = [];
      let nftData = [];
      let tokenIds = [];
      let tokenModes = [];
      for (let i = 0; i < balanceOf; i++) {
        console.dir(i + "/" + balanceOf);
        // 保有トークンID取得
        tokenIds[i] = await store
          .getState()
          .blockchain.smartContract.methods.tokenOfOwnerByIndex(account, i)
          .call();
        // トークンURI取得
        tokenURI[i] = await store
          .getState()
          .blockchain.smartContract.methods.tokenURI(tokenIds[i])
          .call();
        // 着せ替えモード取得
        tokenModes[i] = await store
          .getState()
          .blockchain.smartContract.methods.mapTokenMode(tokenIds[i])
          .call();
        try {
          nftData[i] = await fetch(tokenURI[i])
            .then((response) => response.json())
            .then((data) => {
              return [
                // iterator
                i,
                // 画像URL
                data["image"],
                // プロパティ一個目
                data["attributes"][0]["value"],
                // トークンID
                tokenIds[i],
                // ディスクリプション
                data["description"],
                // 着せ替えモード
                tokenModes[i],
              ];
            });
        } catch {
          nftData[i] = await fetch(tokenURI[i])
            .then((response) => response.json())
            .then((data) => {
              return [
                // iterator
                i,
                // 画像URL
                data["image"],
                // プロパティ一個目
                data["attributes"][0]["value"],
                // トークンID
                tokenIds[i],
                // ディスクリプション
                data["description"],
                // 着せ替えモード
                tokenModes[i],
              ];
            });
        }
      }
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSubSuccess({
          balanceOf,
          tokenURI,
          nftData,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataSubFailed("Could not load data from contract."));
    }
  };
};
