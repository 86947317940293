import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Header from "./components/Header";
import { Grid } from "@material-ui/core";
import Box from "@mui/material/Box";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const [doSometing, setdoSometing] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const changeA = (tokenId) => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setdoSometing(true);
    blockchain.smartContract.methods
      .ModeA(tokenId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かがおかしいようです。");
        setdoSometing(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`着せ替えが完了しました。`);
        setdoSometing(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const changeB = (tokenId) => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setdoSometing(true);
    blockchain.smartContract.methods
      .ModeB(tokenId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かがおかしいようです。");
        setdoSometing(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`着せ替えが完了しました。`);
        setdoSometing(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const changeC = (tokenId) => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setdoSometing(true);
    blockchain.smartContract.methods
      .ModeC(tokenId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かがおかしいようです。");
        setdoSometing(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`着せ替えが完了しました。`);
        setdoSometing(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const changeD = (tokenId) => {
    let cost = 0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setdoSometing(true);
    blockchain.smartContract.methods
      .ModeD(tokenId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かがおかしいようです。");
        setdoSometing(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`着せ替えが完了しました。`);
        setdoSometing(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      console.dir(data);
    }
  };
  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
      console.dir(datasub);
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`ここにメッセージが表示されます。`);
    } else {
      getDataSub();
    }
    getData();
    getConfig();
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/CONFIG.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getData();
    getDataSub();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          // padding: 24,
          backgroundColor: "blue",
          backgroundAttachment: "fixed",
        }}
        image={"/config/images/sky.jpg"}
      >
        <s.ResponsiveWrapper
          flex={1}
          style={{ padding: 24, maxWidth: 600, backgroundAttachment: "fixed" }}
          test
        >
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
            }}
          >
            <Header />

            {blockchain.account === "" || blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Connect to the {CONFIG.NETWORK.NAME} network
                </s.TextDescription>
                <s.SpacerSmall />
                <s.StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  CONNECT
                </s.StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
              </s.Container>
            ) : (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {feedback}
                </s.TextDescription>
                <Grid container direction="column">
                  <Grid item container>
                    <Grid xs={2} sm={2}>
                      <s.StyledKoushaImg src="/config/images/kousha.png" />
                    </Grid>
                    <Grid xs={3} sm={3}>
                      <s.StyledCharactorImg src="/config/images/haduki.png" />
                    </Grid>
                    <Grid xs={7} sm={7}>
                      <s.TextDescription
                        style={{
                          fontSize: "2rem",
                        }}
                      >
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                      <s.TextDescription style={{}}>
                        aaaaaaaaaa
                      </s.TextDescription>
                    </Grid>
                  </Grid>
                </Grid>
                <s.SpacerMedium />
                <s.BoxList>
                  <Grid container direction="column">
                    <Grid item container>
                      <Grid xs={12} sm={12}>
                        {data.loading ? (
                          <>
                            <s.TitlesTitle
                              id="Titles"
                              style={{
                                textAlign: "center",
                                perspective: "500px",
                                color: "primary.main",
                              }}
                            >
                              {"Loading..."}
                            </s.TitlesTitle>
                          </>
                        ) : (
                          <>
                            {datasub.balanceOf > 0 ? (
                              <>
                                <s.TitlesTitle
                                  id="Titles"
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {"保有NFTs:"}
                                  {datasub.balanceOf}
                                  {"体"}
                                </s.TitlesTitle>
                              </>
                            ) : (
                              <>
                                {datasub.balanceOf === false ? (
                                  <>
                                    <s.TitlesTitle
                                      id="Titles"
                                      style={{
                                        textAlign: "",
                                      }}
                                    >
                                      {"ここに所有NFTが表示されます。"}
                                    </s.TitlesTitle>
                                  </>
                                ) : (
                                  <>
                                    {datasub.balanceOf > 0 ? (
                                      <></>
                                    ) : (
                                      <s.TitlesTitle
                                        id="Titles"
                                        style={{
                                          textAlign: "",
                                        }}
                                      >
                                        {"現在保有NFTはありません。"}
                                      </s.TitlesTitle>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        <Grid xs={12} sm={12}>
                          <Box
                            sx={{
                              width: 1 / 1.25,
                              height: 1 / 1,
                              mx: "auto",
                              backgroundColor: "black",
                              "&:hover": {
                                backgroundColor: "primary.main",
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                          ></Box>
                        </Grid>
                      </Grid>
                      {datasub.nftData != undefined
                        ? datasub.nftData?.map((nftData) => (
                            <s.NftList key={nftData[0]}>
                              <Grid container direction="column">
                                <Grid item container>
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    style={{
                                      perspective: "500px",
                                    }}
                                  >
                                    <s.StyledLink
                                      target={"_blank"}
                                      href={
                                        CONFIG.MARKETPLACE_BASEURI + nftData[3]
                                      }
                                    >
                                      <s.StyledTitlesName>
                                        {nftData[2]}
                                      </s.StyledTitlesName>
                                    </s.StyledLink>
                                  </Grid>
                                  <Grid
                                    xs={3}
                                    sm={3}
                                    style={{
                                      perspective: "500px",
                                    }}
                                  >
                                    {/* A */}
                                    <Box
                                      sx={{
                                        width: 1 / 1,
                                        height: 1 / 1,
                                        mx: "auto",
                                        display: "block",
                                      }}
                                    >
                                      <s.StyledTitles
                                        alt={"logo"}
                                        src={`${data.modeATokenURI}`}
                                      />
                                      {nftData[5] == 0 || nftData[5] == 1 ? (
                                        <></>
                                      ) : (
                                        <s.StyledTitlesCover
                                          alt={"logo"}
                                          src={"/config/images/gray.png"}
                                        />
                                      )}
                                      <s.StyledButton
                                        disabled={0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeA(nftData[3]);
                                          getData();
                                        }}
                                      >
                                        {doSometing ? "BUSY" : "ChangeA"}
                                      </s.StyledButton>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    xs={3}
                                    sm={3}
                                    style={{
                                      perspective: "500px",
                                    }}
                                  >
                                    {/* B */}
                                    <Box
                                      sx={{
                                        width: 1 / 1,
                                        height: 1 / 1,
                                        mx: "auto",
                                        display: "block",
                                      }}
                                    >
                                      <s.StyledTitles
                                        alt={"logo"}
                                        src={`${data.modeBTokenURI}`}
                                      />

                                      {nftData[5] == 2 ? (
                                        <></>
                                      ) : (
                                        <s.StyledTitlesCover
                                          alt={"logo"}
                                          src={"/config/images/gray.png"}
                                        />
                                      )}
                                      <s.StyledButton
                                        disabled={0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeB(nftData[3]);
                                          getData();
                                        }}
                                      >
                                        {doSometing ? "BUSY" : "ChangeB"}
                                      </s.StyledButton>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    xs={3}
                                    sm={3}
                                    style={{
                                      perspective: "500px",
                                    }}
                                  >
                                    {/* C */}
                                    <Box
                                      sx={{
                                        width: 1 / 1,
                                        height: 1 / 1,
                                        mx: "auto",
                                        display: "block",
                                      }}
                                    >
                                      <s.StyledTitles
                                        alt={"logo"}
                                        src={`${data.modeCTokenURI}`}
                                      />

                                      {nftData[5] == 3 ? (
                                        <></>
                                      ) : (
                                        <s.StyledTitlesCover
                                          alt={"logo"}
                                          src={"/config/images/gray.png"}
                                        />
                                      )}

                                      <s.StyledButton
                                        disabled={0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeC(nftData[3]);
                                          getData();
                                        }}
                                      >
                                        {doSometing ? "BUSY" : "ChangeC"}
                                      </s.StyledButton>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    xs={3}
                                    sm={3}
                                    style={{
                                      perspective: "500px",
                                    }}
                                  >
                                    {/* D */}
                                    <Box
                                      sx={{
                                        width: 1 / 1,
                                        height: 1 / 1,
                                        mx: "auto",
                                        display: "block",
                                      }}
                                    >
                                      <s.StyledTitles
                                        alt={"logo"}
                                        src={`${data.modeDTokenURI}`}
                                      />

                                      {nftData[5] == 4 ? (
                                        <></>
                                      ) : (
                                        <s.StyledTitlesCover
                                          alt={"logo"}
                                          src={"/config/images/gray.png"}
                                        />
                                      )}

                                      <s.StyledButton
                                        disabled={0}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeD(nftData[3]);
                                          getData();
                                        }}
                                      >
                                        {doSometing ? "BUSY" : "ChangeD"}
                                      </s.StyledButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </s.NftList>
                          ))
                        : ""}

                      <Grid xs={12} sm={12}>
                        <s.BlinkTitle
                          id="Titles"
                          style={{
                            position: "relative",
                            textAlign: "right",
                            top: "100%",
                          }}
                        >
                          {"▼"}
                        </s.BlinkTitle>
                      </Grid>
                    </Grid>
                  </Grid>
                  <s.SpacerLarge />
                </s.BoxList>
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
        </s.ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default App;
